// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { each, uniq, map, sortBy } from "lodash-es";
import Vue from "vue";
import Vuex from "vuex";
import App from "./App";
import appStore from "./store/index.js";
import VueLocalStorage from "vue-localstorage";
import router from "./router";
import axios from "axios";

Vue.config.productionTip = false;
Vue.use(VueLocalStorage);
Vue.use(Vuex);
const store = new Vuex.Store(appStore);

// TODO: NAVIGATION GUARDS FOR LOADING LAZY
/* eslint-disable no-new */
new Vue({
  store,
  router,
  el: "#app",
  render: h => h(App),
  async beforeCreate() {
    // Load up all fandoms names/categories
    // Whether there are prompts for each fandom
    // Characters for the first 350 fandoms because when prompts are in
    // there's usually quite a few that are skipped
    axios
      .get(
        `https://tagset2023-default-rtdb.firebaseio.com/characters.json?orderBy="$key"&limitToFirst=350`
      )
      .then(res => {
        store.commit("setCharsLoaded", true);
        store.commit("setCharacters", res.data);
      });

    axios
      .get(`https://tagset2023-default-rtdb.firebaseio.com/hasPrompts.json`)
      .then(res => {
        store.commit("setHasPrompts", res.data);
        store.commit("setHasPromptsLoaded", true);
      });

    await axios
      .get(`https://tagset2023-default-rtdb.firebaseio.com/letters.json`)
      .then(res => {
        store.commit("setLetters", res.data);
      });

    axios
      .get(`https://tagset2023-default-rtdb.firebaseio.com/fandomsonly.json`)
      .then(res => {
        // store.commit("setHasPrompts", res.data);
        // store.commit("setHasPromptsLoaded", true);
        store.commit("setDbLoaded", true);
        let result = map(res.data, (o, i) => {
          return {
            ...o,
            ".key": i
          };
        });
        store.commit("setFandoms", result);
        let categories = [];
        each(result, o => {
          if (!o) {
            return;
          }
          each(o.category, cat => {
            if (!cat.length) {
              return;
            }
            categories.push(cat);
          });
        });

        categories = sortBy(categories, c => c);
        store.commit("setCategories", uniq(categories));
      });
  },
  created() {
    this.loadBookmarks();
  },
  methods: {
    loadBookmarks() {
      const bookmarksJson = this.$localStorage.get(
        `${process.env.VUE_APP_COLLECTION_ID}bookmarks`
      );
      let bookmarks;
      if (bookmarksJson) {
        try {
          bookmarks = JSON.parse(bookmarksJson);
        } catch {
          bookmarks = [];
        }
        this.$store.commit("setBookmarks", bookmarks);
      }

      const lettermarksJson = this.$localStorage.get(
        `${process.env.VUE_APP_COLLECTION_ID}lettermarks`
      );
      let lettermarks;
      if (lettermarksJson) {
        try {
          lettermarks = JSON.parse(lettermarksJson);
        } catch {
          lettermarks = [];
        }
        this.$store.commit("setLettermarks", lettermarks);
      }

      const promptmarksJson = this.$localStorage.get(
        `${process.env.VUE_APP_COLLECTION_ID}promptmarks`
      );
      let promptmarks;
      if (promptmarksJson) {
        try {
          promptmarks = JSON.parse(promptmarksJson);
        } catch {
          promptmarks = [];
        }
        this.$store.commit("setPromptmarks", promptmarks);
      }
    }
  }
}).$mount("#app");
