const state = {
  // db data
  loadedChars: false,
  loaded: false,
  loadedHasPrompts: false,
  loadAll: {
    characters: false,
    fandoms: false,
    letters: false
  },
  categories: [],
  characters: {},
  fandoms: [],
  hasPrompts: {},
  hasLetters: true,
  letters: {},
  prompts: {},
  // bookmarking
  bookmarks: [],
  lettermarks: [],
  promptmarks: [],
  // options
  options: {
    filter: {
      category: "",
      term: ""
    },
    onlyLetters: false,
    onlyBookmarks: false,
    onlyPrompts: true,
    onlyPHs: false,
    destyle: false,
    loadAll: false,
    hideCharacters: true,
    hideLetters: true,
    tableStyle: "split",
    useToggles: false
  },
  // user lookup
  user: null,
  userPrompts: [],
  // admin options
  unlock: true,
  isModIn: false
};

const mutations = {
  addChar(state, payload) {
    const newVal = state.characters;
    newVal[payload.key] = payload.result;
    state.characters = {};
    state.characters = newVal;
  },
  loadAllChars(state) {
    state.loadAll.characters = true;
  },
  setCharsLoaded(state) {
    state.loadedChars = true;
  },
  setLettersLoaded(state) {
    state.loadAll.letters = true;
  },
  setCharacters(state, characters) {
    state.characters = characters;
  },

  setLetters(state, letters) {
    state.letters = letters;
  },

  // fandom name and category list has been loaded
  setDbLoaded(state, val) {
    state.loaded = val;
  },

  setHasPromptsLoaded(state, val) {
    state.loadedHasPrompts = val;
  },

  setFandoms(state, fandoms) {
    state.fandoms = fandoms;
  },

  setHasPrompts(state, hasPrompts) {
    state.hasPrompts = hasPrompts;
  },

  setPrompts(state, prompts) {
    state.prompts = prompts;
  },

  setPromptmarks(state, promptmarks) {
    state.promptmarks = promptmarks;
  },

  setBookmarks(state, bookmarks) {
    state.bookmarks = bookmarks;
  },

  setLettermarks(state, lettermarks) {
    state.lettermarks = lettermarks;
  },

  setUser(state, user) {
    state.user = user;
  },

  setUserPrompts(state, prompts) {
    state.userPrompts = prompts;
  },

  setCategories(state, categories) {
    state.categories = categories;
  },

  setOptions(state, options) {
    state.options = options;
  },

  setUnlock(state, unlock) {
    state.unlock = unlock;
  },

  setModIn(state, val) {
    state.isModIn = val;
  },

  setHasLetters(state, val) {
    state.hasLetters = val;
  }
};

const getters = {
  loadAll: state => state.loadAll,
  loadedChars: state => state.loadedChars,
  loadedHasPrompts: state => state.loadedHasPrompts,
  characters: state => state.characters,
  letters: state => state.letters,
  hasLetters: state => state.hasLetters,
  hasPrompts: state => state.hasPrompts,
  loaded: state => state.loaded,
  fandoms: state => state.fandoms,
  prompts: state => state.prompts,
  categories: state => state.categories,
  bookmarks: state => state.bookmarks,
  lettermarks: state => state.lettermarks,
  promptmarks: state => state.promptmarks,
  user: state => state.user,
  userPrompts: state => state.userPrompts,
  options: state => state.options,
  unlock: state => state.unlock,
  showEasterEggs: state => state.showEasterEggs,
  isModIn: state => state.isModIn
};

export default {
  state,
  mutations,
  getters
};
