<template>
  <ul class="tab tab-block my-4">
    <li :class="['tab-item', { active: $route.path === '/' }]">
      <router-link to="/">Main</router-link>
    </li>
    <li
      v-if="hasLetters"
      :class="['tab-item', { active: $route.path === '/bookmarks' }]"
    >
      <router-link to="/bookmarks" class="badge" :data-badge="marksCount"
        >Bookmarks</router-link
      >
    </li>
    <li
      :class="['tab-item', { active: $route.path === '/help' }]"
    >
      <router-link to="/help">Help</router-link>
    </li>
    <li v-if="$route.path.indexOf('/fandom') > -1" class="tab-item active">
      <router-link to="/fandom">Fandom Lookup</router-link>
    </li>
    <li
      v-if="unlock"
      :class="['tab-item', { active: $route.path.indexOf('user') > -1 }]"
    >
      <router-link to="/user">User Lookup</router-link>
    </li>
    <li
      v-if="hasLetters || isModIn"
      :class="['tab-item', { active: $route.path === '/letter' }]"
    >
      <router-link to="/letter">Add/Edit Letter</router-link>
    </li>
    <li
      v-if="isModIn"
      :class="['tab-item', { active: $route.path === '/admin' }]"
    >
      <router-link to="/admin">Mod Panel</router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    marksCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(["isModIn", "hasLetters", "unlock"])
  }
};
</script>
