import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "home" */ "./views/Home.vue")
    },
    {
      path: "/user",
      name: "userLookup",
      component: () => import(/* webpackChunkName: "user" */ "./views/User.vue")
    },
    {
      path: "/user/:id",
      name: "user",
      component: () => import(/* webpackChunkName: "user" */ "./views/User.vue")
    },
    {
      path: "/fandom/:id",
      name: "fandom",
      component: () =>
        import(/* webpackChunkName: "fandom" */ "./views/Fandom.vue")
    },
    {
      path: "/bookmarks",
      name: "bookmarks",
      component: () =>
        import(/* webpackChunkName: "bookmarks" */ "./views/Bookmarks.vue")
    },
    {
      path: "/letter",
      name: "letter",
      component: () =>
        import(/* webpackChunkName: "letter" */ "./views/Letter.vue")
    },
    {
      path: "/help",
      name: "help",
      component: () => import(/* webpackChunkName: "help" */ "./views/Help.vue")
    },
    {
      path: "/admin",
      name: "admin",
      component: () =>
        import(/* webpackChunkName: "admin" */ "./views/Admin.vue")
    },
    {
      path: "/bonus",
      name: "bouns",
      component: () =>
        import(/* webpackChunkName: "bonus" */ "./views/Bonus.vue")
    },
    {
      path: "/minichallenges",
      name: "minichallenges",
      component: () =>
        import(
          /* webpackChunkName: "minichallenges" */ "./views/Minichallenges.vue"
        )
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
