<template>
  <div id="app">
    <h1>{{ collectionName }} App</h1>
    <div class="toast toast-primary">
      <div>
        <!-- TODO: collectionId -->
        <a href="https://archiveofourown.org/collections/yuletide2023/profile">
          {{ collectionName }} AO3
        </a>
        |
        <!-- TODO: admin comm -->
        <a href="http://yuletide-admin.dreamwidth.org">yuletide-admin</a>
        |
        <a href="https://discord.gg/49NXABD">Discord</a>
        <template v-if="hasLetters">
          | Assignments due
          {{ new Date(assignmentDeadline).toDateString() }}
          {{ new Date(defaultDeadline).toLocaleTimeString() }}
          (local time)
        </template>
      </div>
    </div>
    <nav-menu :marksCount="marksCount"></nav-menu>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavMenu from "./components/nav-menu";

export default {
  components: {
    NavMenu
  },
  beforeMount() {
    if (this.$localStorage.get("isModIn") === "true") {
      this.$store.commit("setModIn", true);
    }
  },
  data() {
    return {
      collectionName: process.env.VUE_APP_COLLECTION_NAME,
      collectionId: process.env.VUE_APP_COLLECTION_ID,
      defaultDeadline: process.env.VUE_APP_DEFAULT_DEADLINE,
      assignmentDeadline: process.env.VUE_APP_ASSIGNMENT_DEADLINE
    };
  },
  computed: {
    ...mapGetters([
      "isModIn",
      "hasLetters",
      "bookmarks",
      "promptmarks",
      "lettermarks",
      "unlock"
    ]),
    marksCount() {
      let count = 0;
      if (this.bookmarks && this.bookmarks.length) {
        count += this.bookmarks.length;
      }
      if (this.promptmarks && this.promptmarks.length) {
        count += this.promptmarks.length;
      }
      if (this.lettermarks && this.lettermarks.length) {
        count += this.lettermarks.length;
      }

      return count;
    }
  }
};
</script>

<style lang="scss">
@import "./styles/spectre/spectre.scss";
@import "./styles/app.scss";
@import "./styles/mobile.scss";

#app {
  margin: 0 20px;
}
</style>
